import React from 'react'

import {Box, chakra} from '@chakra-ui/react'

export const Logo = (props: any) => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      width="full"
      height="full"
      viewBox="0 0 2400 600"
      sx={{
        '.squarel': {
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: 'none'
        },
        '.k': {
          stroke: '#bae0e2',
          strokeWidth: '15px'
          //strokeDasharray: "5"
        }
      }}
      {...props}>
      <g id="Ebene_1-2">
        <path
          id="Selection"
          fill="currentColor"
          stroke="none"
          strokeWidth="1"
          d="M 2107.00,91.00
           C 2107.00,91.00 2182.00,91.00 2182.00,91.00
             2182.00,91.00 2182.00,301.00 2182.00,301.00
             2182.00,301.00 2223.00,261.00 2223.00,261.00
             2223.00,261.00 2263.00,220.00 2263.00,220.00
             2263.00,220.00 2239.00,218.00 2239.00,218.00
             2239.00,218.00 2239.00,191.00 2239.00,191.00
             2239.00,191.00 2337.00,191.00 2337.00,191.00
             2337.00,191.00 2337.00,218.00 2337.00,218.00
             2337.00,218.00 2309.00,223.93 2309.00,223.93
             2309.00,223.93 2287.00,245.00 2287.00,245.00
             2287.00,245.00 2244.00,288.00 2244.00,288.00
             2249.32,292.43 2261.21,308.65 2266.21,315.00
             2266.21,315.00 2301.79,360.00 2301.79,360.00
             2301.79,360.00 2320.66,383.85 2320.66,383.85
             2324.88,388.33 2337.76,388.99 2344.00,389.87
             2346.49,390.22 2352.41,390.58 2353.98,392.59
             2355.24,394.21 2355.00,397.99 2355.00,400.00
             2355.00,400.00 2355.00,418.00 2355.00,418.00
             2355.00,418.00 2253.00,418.00 2253.00,418.00
             2253.00,418.00 2253.00,399.00 2253.00,399.00
             2253.00,399.00 2254.02,392.60 2254.02,392.60
             2254.02,392.60 2260.00,390.72 2260.00,390.72
             2260.00,390.72 2275.00,389.00 2275.00,389.00
             2275.00,389.00 2236.58,339.00 2236.58,339.00
             2236.58,339.00 2218.00,314.00 2218.00,314.00
             2218.00,314.00 2193.00,339.00 2193.00,339.00
             2186.51,345.49 2182.06,347.48 2182.00,357.00
             2182.00,357.00 2182.00,386.00 2182.00,386.00
             2182.00,386.00 2216.00,391.00 2216.00,391.00
             2216.00,391.00 2216.00,418.00 2216.00,418.00
             2216.00,418.00 2107.00,418.00 2107.00,418.00
             2107.00,418.00 2107.00,391.00 2107.00,391.00
             2107.00,391.00 2141.00,386.00 2141.00,386.00
             2141.00,386.00 2141.00,123.00 2141.00,123.00
             2141.00,123.00 2107.00,118.00 2107.00,118.00
             2107.00,118.00 2107.00,91.00 2107.00,91.00 Z
           M 688.00,113.00
           C 688.00,113.00 756.00,113.00 756.00,113.00
             765.65,113.02 764.11,113.39 773.32,128.00
             773.32,128.00 799.69,170.00 799.69,170.00
             799.69,170.00 880.95,299.00 880.95,299.00
             880.95,299.00 914.00,351.00 914.00,351.00
             914.00,351.00 914.00,145.00 914.00,145.00
             914.00,145.00 877.00,140.00 877.00,140.00
             877.00,140.00 877.00,113.00 877.00,113.00
             877.00,113.00 986.00,113.00 986.00,113.00
             986.00,113.00 986.00,140.00 986.00,140.00
             986.00,140.00 952.00,145.00 952.00,145.00
             952.00,145.00 952.00,418.00 952.00,418.00
             952.00,418.00 922.00,418.00 922.00,418.00
             920.18,418.00 916.94,418.14 915.32,417.43
             911.81,415.89 905.65,404.65 903.31,401.00
             903.31,401.00 869.95,349.00 869.95,349.00
             869.95,349.00 795.34,233.00 795.34,233.00
             795.34,233.00 760.00,179.00 760.00,179.00
             760.00,179.00 760.00,386.00 760.00,386.00
             760.00,386.00 786.00,389.75 786.00,389.75
             786.00,389.75 795.98,392.59 795.98,392.59
             795.98,392.59 797.00,400.00 797.00,400.00
             797.00,400.00 797.00,418.00 797.00,418.00
             797.00,418.00 688.00,418.00 688.00,418.00
             688.00,418.00 688.00,391.00 688.00,391.00
             688.00,391.00 722.00,386.00 722.00,386.00
             722.00,386.00 722.00,145.00 722.00,145.00
             722.00,145.00 688.00,140.00 688.00,140.00
             688.00,140.00 688.00,113.00 688.00,113.00 Z
           M 1267.00,191.00
           C 1267.00,191.00 1267.00,143.00 1267.00,143.00
             1267.14,135.51 1269.26,136.01 1276.00,136.00
             1276.00,136.00 1300.00,136.00 1300.00,136.00
             1301.99,136.00 1305.81,135.77 1307.40,137.02
             1309.38,138.59 1309.00,142.70 1309.00,145.00
             1309.00,145.00 1309.00,191.00 1309.00,191.00
             1309.00,191.00 1358.00,191.00 1358.00,191.00
             1358.00,191.00 1358.00,222.00 1358.00,222.00
             1358.00,222.00 1309.00,222.00 1309.00,222.00
             1309.00,222.00 1309.00,362.00 1309.00,362.00
             1309.18,377.28 1316.03,390.79 1333.00,391.00
             1341.85,391.10 1345.38,390.49 1354.00,389.04
             1356.17,388.73 1360.42,387.62 1362.27,389.04
             1363.88,390.23 1364.43,394.07 1364.88,396.00
             1364.88,396.00 1369.00,414.77 1369.00,414.77
             1345.34,419.76 1314.33,427.50 1292.00,414.77
             1272.70,403.34 1269.31,386.39 1267.91,366.00
             1267.91,366.00 1267.00,354.00 1267.00,354.00
             1267.00,354.00 1267.00,222.00 1267.00,222.00
             1267.00,222.00 1232.00,222.00 1232.00,222.00
             1232.00,222.00 1232.00,191.00 1232.00,191.00
             1232.00,191.00 1267.00,191.00 1267.00,191.00 Z
           M 1204.00,395.00
           C 1192.97,403.95 1182.42,410.68 1169.00,415.56
             1150.11,422.44 1135.47,422.03 1116.00,422.00
             1087.71,421.96 1059.92,407.40 1042.90,385.00
             1027.75,365.07 1020.04,337.89 1020.00,313.00
             1020.00,313.00 1020.00,296.00 1020.00,296.00
             1020.07,249.42 1049.23,201.44 1096.00,189.68
             1110.63,185.73 1129.36,186.41 1144.00,189.68
             1155.90,192.71 1167.51,197.42 1177.00,205.32
             1199.94,224.43 1209.95,254.80 1210.00,284.00
             1210.00,284.00 1210.00,314.00 1210.00,314.00
             1210.00,314.00 1072.00,314.00 1072.00,314.00
             1069.82,314.00 1066.15,313.79 1064.12,315.02
             1060.65,317.48 1063.26,329.05 1064.12,333.00
             1066.93,350.87 1073.54,364.54 1087.00,376.91
             1107.45,395.70 1143.57,392.12 1167.00,381.11
             1167.00,381.11 1188.00,368.00 1188.00,368.00
             1188.00,368.00 1204.00,395.00 1204.00,395.00 Z
           M 1401.00,350.00
           C 1401.00,350.00 1425.00,350.00 1425.00,350.00
             1427.28,350.02 1430.40,349.75 1432.07,351.60
             1433.42,353.10 1433.98,356.99 1434.42,359.00
             1434.42,359.00 1439.98,379.89 1439.98,379.89
             1441.26,381.99 1442.83,382.92 1445.00,383.89
             1460.19,390.69 1476.72,391.19 1493.00,391.00
             1499.37,390.92 1508.22,389.36 1514.00,386.68
             1530.15,379.17 1539.18,358.85 1528.75,343.00
             1519.86,329.48 1501.89,324.35 1487.00,320.63
             1454.04,312.38 1403.50,299.35 1403.00,257.00
             1403.00,257.00 1403.00,247.00 1403.00,247.00
             1403.08,240.67 1404.47,235.84 1406.82,230.00
             1410.32,221.32 1415.11,215.10 1422.04,208.87
             1442.80,190.21 1467.28,186.96 1494.00,187.00
             1511.55,187.03 1532.27,191.43 1548.00,199.25
             1552.40,201.43 1561.59,205.38 1563.40,210.04
             1564.05,211.72 1563.99,214.20 1564.00,216.00
             1564.00,216.00 1564.00,224.00 1564.00,224.00
             1564.00,224.00 1565.00,237.00 1565.00,237.00
             1565.00,237.00 1566.00,260.00 1566.00,260.00
             1566.00,260.00 1534.00,260.00 1534.00,260.00
             1534.00,260.00 1530.21,241.00 1530.21,241.00
             1529.46,237.52 1528.86,233.10 1526.49,230.33
             1518.55,221.04 1496.54,218.87 1485.00,219.00
             1466.30,219.22 1441.14,231.87 1444.30,254.00
             1446.77,271.29 1463.64,276.53 1478.00,280.98
             1505.63,289.53 1541.99,294.26 1561.54,318.00
             1570.01,328.29 1575.67,343.55 1574.91,357.00
             1573.29,385.62 1557.41,407.26 1530.00,416.48
             1506.18,424.50 1466.34,423.58 1442.00,417.53
             1442.00,417.53 1415.00,409.31 1415.00,409.31
             1415.00,409.31 1401.00,404.00 1401.00,404.00
             1401.00,404.00 1401.00,350.00 1401.00,350.00 Z
           M 1682.00,224.00
           C 1682.00,224.00 1692.09,212.00 1692.09,212.00
             1699.36,204.17 1707.34,198.32 1717.00,193.78
             1729.40,187.95 1741.52,186.85 1755.00,187.00
             1761.61,187.08 1772.67,189.02 1779.00,191.03
             1784.49,192.77 1791.22,195.90 1796.00,199.09
             1832.47,223.39 1827.00,274.81 1827.00,313.00
             1827.00,313.00 1827.00,345.00 1827.00,345.00
             1827.00,345.00 1827.00,386.00 1827.00,386.00
             1827.00,386.00 1851.00,389.75 1851.00,389.75
             1851.00,389.75 1859.98,392.58 1859.98,392.58
             1859.98,392.58 1861.00,400.00 1861.00,400.00
             1861.00,400.00 1861.00,418.00 1861.00,418.00
             1861.00,418.00 1751.00,418.00 1751.00,418.00
             1751.00,418.00 1751.00,398.00 1751.00,398.00
             1751.14,390.62 1752.38,391.37 1763.00,389.59
             1767.53,388.83 1782.69,387.24 1784.98,383.70
             1786.21,381.79 1786.00,378.24 1786.00,376.00
             1786.00,376.00 1786.00,282.00 1786.00,282.00
             1786.00,282.00 1785.09,270.00 1785.09,270.00
             1783.84,251.88 1781.15,234.91 1763.00,225.81
             1752.71,220.65 1745.03,220.95 1734.00,221.00
             1721.06,221.06 1709.99,226.01 1700.00,234.00
             1694.74,238.21 1686.06,247.36 1684.14,254.00
             1683.92,255.99 1684.00,258.91 1684.14,261.00
             1684.14,261.00 1684.14,386.00 1684.14,386.00
             1684.14,386.00 1718.00,391.00 1718.00,391.00
             1718.00,391.00 1718.00,418.00 1718.00,418.00
             1718.00,418.00 1609.00,418.00 1609.00,418.00
             1609.00,418.00 1609.00,391.00 1609.00,391.00
             1609.00,391.00 1643.00,386.00 1643.00,386.00
             1643.00,386.00 1643.00,233.00 1643.00,233.00
             1643.00,233.00 1641.40,224.59 1641.40,224.59
             1641.40,224.59 1632.00,221.92 1632.00,221.92
             1632.00,221.92 1609.00,218.00 1609.00,218.00
             1609.00,218.00 1609.00,191.00 1609.00,191.00
             1609.00,191.00 1680.00,191.00 1680.00,191.00
             1680.00,191.00 1682.00,224.00 1682.00,224.00 Z
           M 2070.00,396.00
           C 2059.85,403.03 2052.81,408.76 2041.00,413.55
             2019.54,422.24 2003.51,422.26 1981.00,422.00
             1951.38,421.65 1921.18,403.74 1905.44,379.00
             1894.08,361.17 1887.03,337.18 1887.00,316.00
             1887.00,316.00 1887.00,294.00 1887.00,294.00
             1887.07,249.92 1915.03,204.48 1958.00,190.75
             1974.30,185.54 1995.49,186.10 2012.00,190.13
             2023.55,192.95 2034.75,197.88 2044.00,205.46
             2077.97,233.30 2077.00,274.33 2077.00,314.00
             2077.00,314.00 1939.00,314.00 1939.00,314.00
             1936.81,314.00 1933.16,313.79 1931.31,315.02
             1927.55,317.53 1929.95,327.98 1930.59,332.00
             1933.09,347.81 1939.90,364.71 1952.04,375.57
             1968.12,389.95 1984.43,391.61 2005.00,389.15
             2013.42,388.15 2021.16,386.49 2029.00,383.13
             2044.24,376.60 2049.01,370.78 2055.00,368.00
             2059.06,375.59 2068.47,388.44 2070.00,396.00 Z
           M 1169.00,282.00
           C 1169.00,265.01 1166.38,247.18 1154.56,234.09
             1138.09,215.83 1106.48,214.91 1088.00,230.32
             1074.24,241.80 1064.39,264.12 1064.00,282.00
             1064.00,282.00 1169.00,282.00 1169.00,282.00 Z
           M 2035.00,282.00
           C 2035.00,266.35 2034.85,252.46 2025.21,239.00
             2010.48,218.43 1978.59,212.91 1958.00,227.93
             1942.16,239.48 1931.18,262.42 1931.00,282.00
             1931.00,282.00 2035.00,282.00 2035.00,282.00 Z"
        />
        <path
          id="Path #4"
          fill="currentColor"
          stroke="none"
          strokeWidth="1"
          d="M 691.00,531.85
           C 690.19,531.85 689.49,531.57 688.93,531.00
             688.36,530.43 688.08,529.74 688.08,528.91
             688.08,528.91 688.08,453.10 688.08,453.10
             688.08,452.25 688.36,451.53 688.93,450.96
             689.49,450.40 690.19,450.12 691.00,450.12
             691.00,450.12 745.53,450.12 745.53,450.12
             746.38,450.12 747.09,450.40 747.65,450.96
             748.22,451.53 748.50,452.25 748.50,453.10
             748.50,453.10 748.50,464.05 748.50,464.05
             748.50,464.87 748.22,465.56 747.65,466.13
             747.09,466.69 746.38,466.97 745.53,466.97
             745.53,466.97 708.35,466.97 708.35,466.97
             708.35,466.97 708.35,482.73 708.35,482.73
             708.35,482.73 742.95,482.73 742.95,482.73
             743.81,482.73 744.52,483.02 745.07,483.58
             745.64,484.15 745.92,484.86 745.92,485.72
             745.92,485.72 745.92,495.87 745.92,495.87
             745.92,496.68 745.64,497.38 745.07,497.94
             744.52,498.51 743.81,498.79 742.95,498.79
             742.95,498.79 708.35,498.79 708.35,498.79
             708.35,498.79 708.35,514.99 708.35,514.99
             708.35,514.99 746.46,514.99 746.46,514.99
             747.32,514.99 748.02,515.27 748.58,515.84
             749.15,516.41 749.43,517.11 749.43,517.96
             749.43,517.96 749.43,528.91 749.43,528.91
             749.43,529.74 749.15,530.43 748.58,531.00
             748.02,531.57 747.32,531.85 746.46,531.85
             746.46,531.85 691.00,531.85 691.00,531.85 Z
           M 764.20,531.85
           C 763.37,531.85 762.67,531.57 762.11,531.00
             761.54,530.43 761.26,529.74 761.26,528.91
             761.26,528.91 761.26,474.07 761.26,474.07
             761.26,473.24 761.54,472.55 762.11,471.99
             762.67,471.43 763.37,471.14 764.20,471.14
             764.20,471.14 777.71,471.14 777.71,471.14
             778.53,471.14 779.23,471.43 779.80,471.99
             780.37,472.55 780.65,473.24 780.65,474.07
             780.65,474.07 780.65,478.53 780.65,478.53
             782.65,476.10 785.28,474.07 788.54,472.43
             791.82,470.79 795.67,469.96 800.12,469.96
             804.59,469.96 808.55,470.98 812.01,473.01
             815.48,475.04 818.21,478.01 820.20,481.92
             822.20,485.82 823.21,490.62 823.21,496.34
             823.21,496.34 823.21,528.91 823.21,528.91
             823.21,529.74 822.92,530.43 822.36,531.00
             821.79,531.57 821.09,531.85 820.27,531.85
             820.27,531.85 805.59,531.85 805.59,531.85
             804.76,531.85 804.07,531.57 803.50,531.00
             802.93,530.43 802.65,529.74 802.65,528.91
             802.65,528.91 802.65,497.08 802.65,497.08
             802.65,493.43 801.76,490.60 799.99,488.58
             798.22,486.57 795.66,485.56 792.30,485.56
             789.06,485.56 786.49,486.57 784.58,488.58
             782.68,490.60 781.73,493.43 781.73,497.08
             781.73,497.08 781.73,528.91 781.73,528.91
             781.73,529.74 781.45,530.43 780.88,531.00
             780.33,531.57 779.63,531.85 778.81,531.85
             778.81,531.85 764.20,531.85 764.20,531.85 Z
           M 866.91,531.85
           C 861.89,531.85 857.56,531.06 853.94,529.47
             850.31,527.88 847.53,525.39 845.60,521.99
             843.67,518.59 842.71,514.21 842.71,508.84
             842.71,508.84 842.71,486.45 842.71,486.45
             842.71,486.45 833.52,486.45 833.52,486.45
             832.69,486.45 832.00,486.16 831.43,485.60
             830.87,485.03 830.60,484.33 830.60,483.50
             830.60,483.50 830.60,474.07 830.60,474.07
             830.60,473.24 830.87,472.55 831.43,471.99
             832.00,471.43 832.69,471.14 833.52,471.14
             833.52,471.14 842.71,471.14 842.71,471.14
             842.71,471.14 842.71,451.88 842.71,451.88
             842.71,451.06 842.99,450.37 843.56,449.80
             844.12,449.23 844.82,448.95 845.63,448.95
             845.63,448.95 859.16,448.95 859.16,448.95
             859.98,448.95 860.68,449.23 861.24,449.80
             861.80,450.37 862.08,451.06 862.08,451.88
             862.08,451.88 862.08,471.14 862.08,471.14
             862.08,471.14 876.71,471.14 876.71,471.14
             877.54,471.14 878.24,471.43 878.80,471.99
             879.37,472.55 879.65,473.24 879.65,474.07
             879.65,474.07 879.65,483.50 879.65,483.50
             879.65,484.33 879.37,485.03 878.80,485.60
             878.24,486.16 877.54,486.45 876.71,486.45
             876.71,486.45 862.08,486.45 862.08,486.45
             862.08,486.45 862.08,507.22 862.08,507.22
             862.08,509.89 862.59,511.98 863.61,513.51
             864.64,515.05 866.35,515.81 868.74,515.81
             868.74,515.81 877.73,515.81 877.73,515.81
             878.56,515.81 879.26,516.09 879.82,516.66
             880.39,517.22 880.67,517.92 880.67,518.75
             880.67,518.75 880.67,528.91 880.67,528.91
             880.67,529.74 880.39,530.43 879.82,531.00
             879.26,531.57 878.56,531.85 877.73,531.85
             877.73,531.85 866.91,531.85 866.91,531.85 Z
           M 918.18,533.01
           C 908.97,533.01 901.65,530.52 896.21,525.53
             890.78,520.54 887.95,513.13 887.72,503.32
             887.69,502.79 887.67,502.11 887.67,501.28
             887.67,500.45 887.69,499.79 887.72,499.31
             887.96,493.14 889.34,487.86 891.86,483.47
             894.37,479.08 897.87,475.73 902.35,473.42
             906.82,471.12 912.07,469.96 918.10,469.96
             924.92,469.96 930.57,471.33 935.06,474.07
             939.56,476.80 942.93,480.50 945.19,485.19
             947.45,489.87 948.58,495.19 948.58,501.12
             948.58,501.12 948.58,503.97 948.58,503.97
             948.58,504.78 948.29,505.48 947.70,506.04
             947.13,506.61 946.42,506.89 945.60,506.89
             945.60,506.89 908.28,506.89 908.28,506.89
             908.28,506.95 908.28,507.06 908.28,507.22
             908.28,507.38 908.28,507.54 908.28,507.69
             908.33,509.98 908.72,512.05 909.44,513.89
             910.17,515.73 911.27,517.19 912.73,518.28
             914.18,519.36 915.95,519.90 918.04,519.90
             919.58,519.90 920.88,519.66 921.93,519.19
             922.98,518.71 923.87,518.13 924.58,517.46
             925.30,516.79 925.85,516.21 926.24,515.73
             926.94,514.92 927.50,514.42 927.93,514.22
             928.37,514.02 929.05,513.92 929.96,513.92
             929.96,513.92 944.45,513.92 944.45,513.92
             945.20,513.92 945.83,514.15 946.33,514.61
             946.84,515.06 947.05,515.66 946.98,516.41
             946.89,517.74 946.24,519.35 945.01,521.23
             943.79,523.12 941.98,524.97 939.60,526.77
             937.23,528.57 934.25,530.07 930.65,531.25
             927.07,532.42 922.91,533.01 918.18,533.01 Z
           M 908.28,495.32
           C 908.28,495.32 928.03,495.32 928.03,495.32
             928.03,495.32 928.03,495.06 928.03,495.06
             928.03,492.54 927.64,490.35 926.86,488.51
             926.10,486.66 924.97,485.23 923.48,484.23
             921.99,483.22 920.20,482.72 918.10,482.72
             916.02,482.72 914.24,483.22 912.76,484.23
             911.28,485.23 910.16,486.66 909.41,488.51
             908.65,490.35 908.28,492.54 908.28,495.06
             908.28,495.06 908.28,495.32 908.28,495.32 Z
           M 962.49,531.85
           C 961.66,531.85 960.96,531.57 960.40,531.00
             959.83,530.43 959.55,529.74 959.55,528.91
             959.55,528.91 959.55,474.12 959.55,474.12
             959.55,473.29 959.83,472.59 960.40,472.01
             960.96,471.43 961.66,471.14 962.49,471.14
             962.49,471.14 975.95,471.14 975.95,471.14
             976.77,471.14 977.48,471.43 978.06,472.01
             978.64,472.59 978.94,473.29 978.94,474.12
             978.94,474.12 978.94,478.68 978.94,478.68
             981.01,476.30 983.51,474.45 986.42,473.12
             989.34,471.80 992.62,471.14 996.27,471.14
             996.27,471.14 1001.40,471.14 1001.40,471.14
             1002.22,471.14 1002.91,471.43 1003.47,471.99
             1004.04,472.55 1004.32,473.24 1004.32,474.07
             1004.32,474.07 1004.32,486.07 1004.32,486.07
             1004.32,486.90 1004.04,487.59 1003.47,488.16
             1002.91,488.72 1002.22,488.99 1001.40,488.99
             1001.40,488.99 990.10,488.99 990.10,488.99
             986.88,488.99 984.40,489.87 982.65,491.62
             980.90,493.37 980.02,495.86 980.02,499.07
             980.02,499.07 980.02,528.91 980.02,528.91
             980.02,529.74 979.73,530.43 979.16,531.00
             978.58,531.57 977.88,531.85 977.05,531.85
             977.05,531.85 962.49,531.85 962.49,531.85 Z
           M 1015.88,554.04
           C 1015.05,554.04 1014.36,553.76 1013.79,553.19
             1013.22,552.63 1012.94,551.93 1012.94,551.10
             1012.94,551.10 1012.94,474.07 1012.94,474.07
             1012.94,473.24 1013.22,472.55 1013.79,471.99
             1014.36,471.43 1015.05,471.14 1015.88,471.14
             1015.88,471.14 1029.03,471.14 1029.03,471.14
             1029.85,471.14 1030.55,471.43 1031.10,471.99
             1031.67,472.55 1031.95,473.24 1031.95,474.07
             1031.95,474.07 1031.95,478.31 1031.95,478.31
             1033.81,475.92 1036.22,473.92 1039.17,472.34
             1042.13,470.76 1045.78,469.96 1050.12,469.96
             1053.98,469.96 1057.39,470.57 1060.36,471.77
             1063.32,472.98 1065.87,474.75 1067.98,477.09
             1070.10,479.42 1071.75,482.26 1072.92,485.63
             1074.09,488.99 1074.77,492.85 1074.95,497.20
             1075.03,498.71 1075.08,500.14 1075.08,501.50
             1075.08,502.85 1075.03,504.30 1074.95,505.85
             1074.83,510.00 1074.17,513.76 1073.00,517.10
             1071.83,520.44 1070.18,523.29 1068.06,525.65
             1065.94,528.00 1063.38,529.82 1060.39,531.09
             1057.40,532.37 1053.98,533.01 1050.12,533.01
             1046.26,533.01 1042.91,532.34 1040.07,530.98
             1037.24,529.63 1034.90,527.70 1033.05,525.18
             1033.05,525.18 1033.05,551.10 1033.05,551.10
             1033.05,551.93 1032.77,552.63 1032.21,553.19
             1031.64,553.76 1030.94,554.04 1030.11,554.04
             1030.11,554.04 1015.88,554.04 1015.88,554.04 Z
           M 1043.80,517.41
           C 1046.44,517.41 1048.50,516.86 1049.98,515.76
             1051.46,514.66 1052.52,513.18 1053.19,511.33
             1053.85,509.47 1054.25,507.41 1054.41,505.13
             1054.58,502.71 1054.58,500.29 1054.41,497.86
             1054.25,495.59 1053.85,493.52 1053.19,491.67
             1052.52,489.80 1051.46,488.32 1049.98,487.22
             1048.50,486.11 1046.44,485.56 1043.80,485.56
             1041.26,485.56 1039.21,486.13 1037.65,487.26
             1036.09,488.40 1034.97,489.87 1034.27,491.68
             1033.56,493.50 1033.16,495.44 1033.05,497.50
             1032.97,499.01 1032.93,500.47 1032.93,501.87
             1032.93,503.28 1032.97,504.76 1033.05,506.32
             1033.13,508.27 1033.55,510.09 1034.33,511.77
             1035.11,513.43 1036.28,514.79 1037.82,515.84
             1039.37,516.89 1041.36,517.41 1043.80,517.41 Z
           M 1089.31,531.85
           C 1088.48,531.85 1087.79,531.57 1087.22,531.00
             1086.65,530.43 1086.37,529.74 1086.37,528.91
             1086.37,528.91 1086.37,474.12 1086.37,474.12
             1086.37,473.29 1086.65,472.59 1087.22,472.01
             1087.79,471.43 1088.48,471.14 1089.31,471.14
             1089.31,471.14 1102.77,471.14 1102.77,471.14
             1103.60,471.14 1104.30,471.43 1104.88,472.01
             1105.47,472.59 1105.76,473.29 1105.76,474.12
             1105.76,474.12 1105.76,478.68 1105.76,478.68
             1107.84,476.30 1110.33,474.45 1113.25,473.12
             1116.16,471.80 1119.44,471.14 1123.09,471.14
             1123.09,471.14 1128.22,471.14 1128.22,471.14
             1129.05,471.14 1129.74,471.43 1130.30,471.99
             1130.86,472.55 1131.15,473.24 1131.15,474.07
             1131.15,474.07 1131.15,486.07 1131.15,486.07
             1131.15,486.90 1130.86,487.59 1130.30,488.16
             1129.74,488.72 1129.05,488.99 1128.22,488.99
             1128.22,488.99 1116.93,488.99 1116.93,488.99
             1113.71,488.99 1111.22,489.87 1109.47,491.62
             1107.72,493.37 1106.85,495.86 1106.85,499.07
             1106.85,499.07 1106.85,528.91 1106.85,528.91
             1106.85,529.74 1106.56,530.43 1105.98,531.00
             1105.40,531.57 1104.70,531.85 1103.87,531.85
             1103.87,531.85 1089.31,531.85 1089.31,531.85 Z
           M 1142.66,531.85
           C 1141.83,531.85 1141.13,531.57 1140.57,531.00
             1140.00,530.43 1139.72,529.74 1139.72,528.91
             1139.72,528.91 1139.72,474.07 1139.72,474.07
             1139.72,473.24 1140.00,472.55 1140.57,471.99
             1141.13,471.43 1141.83,471.14 1142.66,471.14
             1142.66,471.14 1156.47,471.14 1156.47,471.14
             1157.29,471.14 1157.98,471.43 1158.54,471.99
             1159.11,472.55 1159.39,473.24 1159.39,474.07
             1159.39,474.07 1159.39,528.91 1159.39,528.91
             1159.39,529.74 1159.11,530.43 1158.54,531.00
             1157.98,531.57 1157.29,531.85 1156.47,531.85
             1156.47,531.85 1142.66,531.85 1142.66,531.85 Z
           M 1142.53,462.43
           C 1141.71,462.43 1141.02,462.15 1140.46,461.60
             1139.89,461.03 1139.61,460.33 1139.61,459.50
             1139.61,459.50 1139.61,449.01 1139.61,449.01
             1139.61,448.19 1139.89,447.48 1140.46,446.91
             1141.02,446.32 1141.71,446.03 1142.53,446.03
             1142.53,446.03 1156.53,446.03 1156.53,446.03
             1157.35,446.03 1158.06,446.32 1158.64,446.91
             1159.22,447.48 1159.52,448.19 1159.52,449.01
             1159.52,449.01 1159.52,459.50 1159.52,459.50
             1159.52,460.33 1159.22,461.03 1158.64,461.60
             1158.06,462.15 1157.35,462.43 1156.53,462.43
             1156.53,462.43 1142.53,462.43 1142.53,462.43 Z
           M 1197.97,533.01
           C 1192.95,533.01 1188.66,532.46 1185.11,531.35
             1181.56,530.24 1178.67,528.85 1176.44,527.18
             1174.21,525.51 1172.55,523.81 1171.49,522.07
             1170.42,520.32 1169.84,518.81 1169.77,517.55
             1169.70,516.70 1169.96,515.99 1170.56,515.45
             1171.16,514.89 1171.80,514.61 1172.49,514.61
             1172.49,514.61 1185.84,514.61 1185.84,514.61
             1186.06,514.61 1186.26,514.64 1186.44,514.71
             1186.62,514.76 1186.84,514.92 1187.09,515.18
             1188.10,515.73 1189.12,516.41 1190.12,517.22
             1191.13,518.04 1192.30,518.76 1193.63,519.36
             1194.96,519.96 1196.57,520.26 1198.47,520.26
             1200.61,520.26 1202.43,519.83 1203.93,518.97
             1205.44,518.10 1206.20,516.84 1206.20,515.18
             1206.20,513.93 1205.83,512.89 1205.11,512.05
             1204.39,511.21 1202.98,510.42 1200.85,509.67
             1198.72,508.93 1195.52,508.13 1191.25,507.28
             1187.17,506.41 1183.63,505.21 1180.61,503.67
             1177.59,502.13 1175.25,500.13 1173.61,497.67
             1171.97,495.22 1171.16,492.26 1171.16,488.79
             1171.16,485.67 1172.15,482.68 1174.14,479.81
             1176.14,476.94 1179.10,474.58 1183.00,472.73
             1186.90,470.89 1191.71,469.96 1197.45,469.96
             1201.89,469.96 1205.79,470.50 1209.14,471.57
             1212.48,472.63 1215.29,473.97 1217.55,475.58
             1219.82,477.18 1221.55,478.86 1222.74,480.59
             1223.92,482.34 1224.55,483.88 1224.63,485.22
             1224.71,486.05 1224.48,486.74 1223.94,487.31
             1223.39,487.88 1222.76,488.16 1222.06,488.16
             1222.06,488.16 1209.88,488.16 1209.88,488.16
             1209.55,488.16 1209.24,488.11 1208.95,488.00
             1208.65,487.90 1208.40,487.76 1208.18,487.58
             1207.21,487.12 1206.26,486.50 1205.31,485.72
             1204.37,484.95 1203.29,484.26 1202.06,483.65
             1200.84,483.03 1199.28,482.72 1197.39,482.72
             1195.29,482.72 1193.66,483.19 1192.50,484.13
             1191.33,485.07 1190.75,486.26 1190.75,487.70
             1190.75,488.73 1191.07,489.67 1191.71,490.52
             1192.36,491.37 1193.72,492.15 1195.80,492.86
             1197.88,493.57 1201.12,494.33 1205.49,495.16
             1210.85,496.05 1215.12,497.45 1218.29,499.37
             1221.47,501.28 1223.73,503.50 1225.08,506.03
             1226.44,508.54 1227.11,511.18 1227.11,513.94
             1227.11,517.69 1225.99,521.01 1223.75,523.91
             1221.51,526.79 1218.23,529.03 1213.89,530.62
             1209.55,532.22 1204.24,533.01 1197.97,533.01 Z
           M 1265.44,533.01
           C 1256.23,533.01 1248.91,530.52 1243.47,525.53
             1238.04,520.54 1235.20,513.13 1234.98,503.32
             1234.94,502.79 1234.93,502.11 1234.93,501.28
             1234.93,500.45 1234.94,499.79 1234.98,499.31
             1235.22,493.14 1236.60,487.86 1239.11,483.47
             1241.63,479.08 1245.12,475.73 1249.60,473.42
             1254.08,471.12 1259.33,469.96 1265.36,469.96
             1272.17,469.96 1277.82,471.33 1282.31,474.07
             1286.81,476.80 1290.19,480.50 1292.44,485.19
             1294.71,489.87 1295.84,495.19 1295.84,501.12
             1295.84,501.12 1295.84,503.97 1295.84,503.97
             1295.84,504.78 1295.55,505.48 1294.96,506.04
             1294.38,506.61 1293.68,506.89 1292.85,506.89
             1292.85,506.89 1255.53,506.89 1255.53,506.89
             1255.53,506.95 1255.53,507.06 1255.53,507.22
             1255.53,507.38 1255.53,507.54 1255.53,507.69
             1255.58,509.98 1255.97,512.05 1256.70,513.89
             1257.43,515.73 1258.52,517.19 1259.98,518.28
             1261.44,519.36 1263.21,519.90 1265.30,519.90
             1266.84,519.90 1268.13,519.66 1269.18,519.19
             1270.24,518.71 1271.13,518.13 1271.84,517.46
             1272.55,516.79 1273.10,516.21 1273.49,515.73
             1274.19,514.92 1274.76,514.42 1275.19,514.22
             1275.63,514.02 1276.31,513.92 1277.22,513.92
             1277.22,513.92 1291.70,513.92 1291.70,513.92
             1292.46,513.92 1293.09,514.15 1293.59,514.61
             1294.09,515.06 1294.31,515.66 1294.24,516.41
             1294.15,517.74 1293.50,519.35 1292.27,521.23
             1291.04,523.12 1289.24,524.97 1286.86,526.77
             1284.49,528.57 1281.50,530.07 1277.91,531.25
             1274.33,532.42 1270.17,533.01 1265.44,533.01 Z
           M 1255.53,495.32
           C 1255.53,495.32 1275.28,495.32 1275.28,495.32
             1275.28,495.32 1275.28,495.06 1275.28,495.06
             1275.28,492.54 1274.90,490.35 1274.12,488.51
             1273.35,486.66 1272.23,485.23 1270.74,484.23
             1269.25,483.22 1267.46,482.72 1265.36,482.72
             1263.27,482.72 1261.49,483.22 1260.01,484.23
             1258.54,485.23 1257.42,486.66 1256.66,488.51
             1255.91,490.35 1255.53,492.54 1255.53,495.06
             1255.53,495.06 1255.53,495.32 1255.53,495.32 Z
           M 1363.77,533.01
           C 1356.00,533.01 1349.55,531.90 1344.39,529.69
             1339.24,527.47 1335.35,524.58 1332.72,521.00
             1330.10,517.41 1328.71,513.61 1328.55,509.60
             1328.55,508.93 1328.80,508.35 1329.29,507.85
             1329.78,507.36 1330.38,507.11 1331.08,507.11
             1331.08,507.11 1345.82,507.11 1345.82,507.11
             1346.89,507.11 1347.71,507.31 1348.29,507.71
             1348.86,508.10 1349.41,508.60 1349.93,509.23
             1350.52,510.39 1351.38,511.50 1352.51,512.57
             1353.64,513.64 1355.12,514.50 1356.97,515.16
             1358.83,515.82 1361.09,516.15 1363.77,516.15
             1368.28,516.15 1371.70,515.46 1374.00,514.06
             1376.32,512.66 1377.48,510.77 1377.48,508.38
             1377.48,506.63 1376.86,505.21 1375.61,504.11
             1374.36,503.00 1372.35,502.00 1369.59,501.12
             1366.83,500.23 1363.14,499.29 1358.51,498.30
             1352.60,497.09 1347.56,495.50 1343.40,493.52
             1339.24,491.54 1336.07,488.94 1333.92,485.71
             1331.75,482.47 1330.68,478.44 1330.68,473.61
             1330.68,468.82 1332.00,464.57 1334.66,460.86
             1337.32,457.15 1341.06,454.24 1345.90,452.13
             1350.74,450.01 1356.46,448.95 1363.04,448.95
             1368.41,448.95 1373.15,449.66 1377.26,451.07
             1381.37,452.49 1384.82,454.35 1387.61,456.66
             1390.40,458.97 1392.52,461.44 1393.98,464.08
             1395.44,466.72 1396.22,469.27 1396.29,471.72
             1396.29,472.37 1396.06,472.94 1395.60,473.44
             1395.13,473.94 1394.55,474.19 1393.84,474.19
             1393.84,474.19 1378.36,474.19 1378.36,474.19
             1377.54,474.19 1376.81,474.04 1376.17,473.74
             1375.55,473.42 1374.98,472.90 1374.49,472.15
             1374.07,470.45 1372.85,468.97 1370.83,467.71
             1368.81,466.45 1366.22,465.81 1363.04,465.81
             1359.67,465.81 1357.01,466.42 1355.08,467.64
             1353.15,468.84 1352.19,470.67 1352.19,473.11
             1352.19,474.71 1352.71,476.08 1353.76,477.21
             1354.81,478.35 1356.57,479.34 1359.05,480.20
             1361.53,481.06 1364.91,481.97 1369.18,482.94
             1376.23,484.23 1381.95,485.87 1386.32,487.86
             1390.69,489.84 1393.89,492.41 1395.93,495.57
             1397.97,498.71 1398.99,502.67 1398.99,507.43
             1398.99,512.76 1397.49,517.34 1394.48,521.16
             1391.47,524.97 1387.33,527.90 1382.04,529.95
             1376.76,531.99 1370.66,533.01 1363.77,533.01 Z
           M 1438.28,533.01
           C 1431.71,533.01 1426.18,531.97 1421.69,529.88
             1417.21,527.79 1413.78,524.77 1411.40,520.82
             1409.03,516.87 1407.70,512.19 1407.41,506.76
             1407.32,505.20 1407.28,503.44 1407.28,501.46
             1407.28,499.48 1407.32,497.74 1407.41,496.23
             1407.70,490.72 1409.10,486.02 1411.59,482.10
             1414.09,478.20 1417.57,475.20 1422.05,473.11
             1426.54,471.01 1431.95,469.96 1438.28,469.96
             1444.61,469.96 1450.02,471.01 1454.50,473.11
             1458.98,475.20 1462.47,478.20 1464.97,482.10
             1467.46,486.02 1468.86,490.72 1469.17,496.23
             1469.30,497.74 1469.36,499.48 1469.36,501.46
             1469.36,503.44 1469.30,505.20 1469.17,506.76
             1468.86,512.19 1467.52,516.87 1465.14,520.82
             1462.77,524.77 1459.35,527.79 1454.87,529.88
             1450.40,531.97 1444.86,533.01 1438.28,533.01 Z
           M 1438.28,518.80
           C 1441.74,518.80 1444.28,517.74 1445.91,515.63
             1447.53,513.52 1448.43,510.37 1448.61,506.18
             1448.70,505.01 1448.74,503.45 1448.74,501.50
             1448.74,499.54 1448.70,497.97 1448.61,496.81
             1448.43,492.69 1447.53,489.56 1445.91,487.40
             1444.28,485.26 1441.74,484.18 1438.28,484.18
             1434.87,484.18 1432.34,485.26 1430.70,487.40
             1429.05,489.56 1428.14,492.69 1427.95,496.81
             1427.87,497.97 1427.84,499.54 1427.84,501.50
             1427.84,503.45 1427.87,505.01 1427.95,506.18
             1428.14,510.37 1429.05,513.52 1430.70,515.63
             1432.34,517.74 1434.87,518.80 1438.28,518.80 Z
           M 1489.82,531.85
           C 1489.00,531.85 1488.31,531.57 1487.74,531.00
             1487.18,530.43 1486.89,529.74 1486.89,528.91
             1486.89,528.91 1486.89,486.45 1486.89,486.45
             1486.89,486.45 1477.96,486.45 1477.96,486.45
             1477.13,486.45 1476.44,486.16 1475.87,485.60
             1475.31,485.03 1475.04,484.33 1475.04,483.50
             1475.04,483.50 1475.04,474.07 1475.04,474.07
             1475.04,473.24 1475.31,472.55 1475.87,471.99
             1476.44,471.43 1477.13,471.14 1477.96,471.14
             1477.96,471.14 1486.89,471.14 1486.89,471.14
             1486.89,471.14 1486.89,467.40 1486.89,467.40
             1486.89,462.10 1487.92,457.83 1489.99,454.61
             1492.06,451.40 1495.00,449.06 1498.82,447.61
             1502.64,446.17 1507.20,445.44 1512.48,445.44
             1512.48,445.44 1521.54,445.44 1521.54,445.44
             1522.37,445.44 1523.07,445.73 1523.63,446.29
             1524.20,446.86 1524.48,447.56 1524.48,448.39
             1524.48,448.39 1524.48,457.82 1524.48,457.82
             1524.48,458.65 1524.20,459.35 1523.63,459.91
             1523.07,460.48 1522.37,460.76 1521.54,460.76
             1521.54,460.76 1513.35,460.76 1513.35,460.76
             1510.74,460.76 1508.90,461.39 1507.84,462.63
             1506.79,463.87 1506.27,465.65 1506.27,467.98
             1506.27,467.98 1506.27,471.14 1506.27,471.14
             1506.27,471.14 1520.38,471.14 1520.38,471.14
             1521.20,471.14 1521.90,471.43 1522.45,471.99
             1523.02,472.55 1523.30,473.24 1523.30,474.07
             1523.30,474.07 1523.30,483.50 1523.30,483.50
             1523.30,484.33 1523.02,485.03 1522.45,485.60
             1521.90,486.16 1521.20,486.45 1520.38,486.45
             1520.38,486.45 1506.27,486.45 1506.27,486.45
             1506.27,486.45 1506.27,528.91 1506.27,528.91
             1506.27,529.74 1505.99,530.43 1505.42,531.00
             1504.86,531.57 1504.17,531.85 1503.34,531.85
             1503.34,531.85 1489.82,531.85 1489.82,531.85 Z
           M 1562.43,531.85
           C 1557.41,531.85 1553.08,531.06 1549.46,529.47
             1545.83,527.88 1543.05,525.39 1541.12,521.99
             1539.19,518.59 1538.23,514.21 1538.23,508.84
             1538.23,508.84 1538.23,486.45 1538.23,486.45
             1538.23,486.45 1529.04,486.45 1529.04,486.45
             1528.21,486.45 1527.52,486.16 1526.95,485.60
             1526.39,485.03 1526.12,484.33 1526.12,483.50
             1526.12,483.50 1526.12,474.07 1526.12,474.07
             1526.12,473.24 1526.39,472.55 1526.95,471.99
             1527.52,471.43 1528.21,471.14 1529.04,471.14
             1529.04,471.14 1538.23,471.14 1538.23,471.14
             1538.23,471.14 1538.23,451.88 1538.23,451.88
             1538.23,451.06 1538.51,450.37 1539.08,449.80
             1539.64,449.23 1540.34,448.95 1541.15,448.95
             1541.15,448.95 1554.68,448.95 1554.68,448.95
             1555.50,448.95 1556.20,449.23 1556.75,449.80
             1557.32,450.37 1557.60,451.06 1557.60,451.88
             1557.60,451.88 1557.60,471.14 1557.60,471.14
             1557.60,471.14 1572.23,471.14 1572.23,471.14
             1573.06,471.14 1573.76,471.43 1574.32,471.99
             1574.89,472.55 1575.17,473.24 1575.17,474.07
             1575.17,474.07 1575.17,483.50 1575.17,483.50
             1575.17,484.33 1574.89,485.03 1574.32,485.60
             1573.76,486.16 1573.06,486.45 1572.23,486.45
             1572.23,486.45 1557.60,486.45 1557.60,486.45
             1557.60,486.45 1557.60,507.22 1557.60,507.22
             1557.60,509.89 1558.11,511.98 1559.13,513.51
             1560.16,515.05 1561.87,515.81 1564.26,515.81
             1564.26,515.81 1573.25,515.81 1573.25,515.81
             1574.08,515.81 1574.78,516.09 1575.34,516.66
             1575.91,517.22 1576.19,517.92 1576.19,518.75
             1576.19,518.75 1576.19,528.91 1576.19,528.91
             1576.19,529.74 1575.91,530.43 1575.34,531.00
             1574.78,531.57 1574.08,531.85 1573.25,531.85
             1573.25,531.85 1562.43,531.85 1562.43,531.85 Z
           M 1602.55,531.85
           C 1601.44,531.85 1600.59,531.57 1600.02,531.00
             1599.45,530.43 1599.03,529.74 1598.75,528.92
             1598.75,528.92 1581.92,474.82 1581.92,474.82
             1581.80,474.46 1581.74,474.11 1581.74,473.77
             1581.74,473.05 1582.00,472.43 1582.52,471.91
             1583.04,471.40 1583.66,471.14 1584.37,471.14
             1584.37,471.14 1596.45,471.14 1596.45,471.14
             1597.46,471.14 1598.26,471.42 1598.84,471.96
             1599.41,472.51 1599.77,473.04 1599.89,473.55
             1599.89,473.55 1609.72,506.94 1609.72,506.94
             1609.72,506.94 1620.21,473.77 1620.21,473.77
             1620.37,473.20 1620.74,472.63 1621.33,472.04
             1621.92,471.44 1622.77,471.14 1623.88,471.14
             1623.88,471.14 1631.54,471.14 1631.54,471.14
             1632.65,471.14 1633.50,471.44 1634.10,472.04
             1634.71,472.63 1635.08,473.20 1635.20,473.77
             1635.20,473.77 1645.68,506.94 1645.68,506.94
             1645.68,506.94 1655.52,473.55 1655.52,473.55
             1655.65,473.04 1655.99,472.51 1656.56,471.96
             1657.13,471.42 1657.94,471.14 1658.97,471.14
             1658.97,471.14 1671.03,471.14 1671.03,471.14
             1671.75,471.14 1672.37,471.40 1672.88,471.91
             1673.40,472.43 1673.65,473.05 1673.65,473.77
             1673.65,474.11 1673.60,474.46 1673.50,474.82
             1673.50,474.82 1656.67,528.92 1656.67,528.92
             1656.41,529.74 1655.99,530.43 1655.41,531.00
             1654.84,531.57 1654.00,531.85 1652.86,531.85
             1652.86,531.85 1642.34,531.85 1642.34,531.85
             1641.23,531.85 1640.36,531.57 1639.75,531.00
             1639.14,530.43 1638.70,529.74 1638.44,528.92
             1638.44,528.92 1627.75,496.94 1627.75,496.94
             1627.75,496.94 1616.97,528.92 1616.97,528.92
             1616.71,529.74 1616.27,530.43 1615.67,531.00
             1615.07,531.57 1614.21,531.85 1613.07,531.85
             1613.07,531.85 1602.55,531.85 1602.55,531.85 Z
           M 1699.48,533.01
           C 1695.30,533.01 1691.56,532.21 1688.23,530.61
             1684.91,529.00 1682.29,526.84 1680.37,524.11
             1678.45,521.38 1677.49,518.30 1677.49,514.90
             1677.49,509.36 1679.74,504.98 1684.24,501.76
             1688.74,498.55 1694.70,496.35 1702.14,495.17
             1702.14,495.17 1716.79,492.97 1716.79,492.97
             1716.79,492.97 1716.79,491.37 1716.79,491.37
             1716.79,488.56 1716.20,486.41 1715.03,484.94
             1713.87,483.46 1711.74,482.72 1708.65,482.72
             1706.45,482.72 1704.67,483.15 1703.32,484.02
             1701.97,484.90 1700.89,486.05 1700.06,487.50
             1699.38,488.47 1698.42,488.96 1697.18,488.96
             1697.18,488.96 1684.07,488.96 1684.07,488.96
             1683.24,488.96 1682.59,488.72 1682.12,488.24
             1681.65,487.76 1681.45,487.14 1681.50,486.38
             1681.55,485.05 1682.09,483.46 1683.12,481.62
             1684.16,479.76 1685.76,477.95 1687.94,476.19
             1690.11,474.42 1692.92,472.94 1696.35,471.76
             1699.78,470.56 1703.92,469.96 1708.79,469.96
             1713.82,469.96 1718.12,470.55 1721.70,471.72
             1725.29,472.90 1728.20,474.53 1730.43,476.62
             1732.66,478.69 1734.31,481.17 1735.37,484.05
             1736.44,486.93 1736.97,490.04 1736.97,493.40
             1736.97,493.40 1736.97,528.91 1736.97,528.91
             1736.97,529.74 1736.69,530.43 1736.12,531.00
             1735.56,531.57 1734.87,531.85 1734.05,531.85
             1734.05,531.85 1720.52,531.85 1720.52,531.85
             1719.70,531.85 1719.01,531.57 1718.45,531.00
             1717.88,530.43 1717.60,529.74 1717.60,528.91
             1717.60,528.91 1717.60,524.82 1717.60,524.82
             1716.57,526.32 1715.21,527.68 1713.52,528.91
             1711.83,530.14 1709.83,531.13 1707.50,531.88
             1705.18,532.64 1702.51,533.01 1699.48,533.01 Z
           M 1704.95,519.68
           C 1707.27,519.68 1709.33,519.19 1711.15,518.21
             1712.96,517.24 1714.39,515.73 1715.44,513.67
             1716.49,511.60 1717.01,509.02 1717.01,505.92
             1717.01,505.92 1717.01,504.36 1717.01,504.36
             1717.01,504.36 1707.06,506.09 1707.06,506.09
             1703.40,506.71 1700.74,507.66 1699.07,508.94
             1697.41,510.21 1696.58,511.70 1696.58,513.43
             1696.58,514.73 1696.97,515.85 1697.73,516.78
             1698.51,517.71 1699.53,518.42 1700.80,518.92
             1702.08,519.43 1703.46,519.68 1704.95,519.68 Z
           M 1752.62,531.85
           C 1751.79,531.85 1751.10,531.57 1750.53,531.00
             1749.96,530.43 1749.68,529.74 1749.68,528.91
             1749.68,528.91 1749.68,474.12 1749.68,474.12
             1749.68,473.29 1749.96,472.59 1750.53,472.01
             1751.10,471.43 1751.79,471.14 1752.62,471.14
             1752.62,471.14 1766.08,471.14 1766.08,471.14
             1766.91,471.14 1767.61,471.43 1768.19,472.01
             1768.78,472.59 1769.07,473.29 1769.07,474.12
             1769.07,474.12 1769.07,478.68 1769.07,478.68
             1771.15,476.30 1773.64,474.45 1776.56,473.12
             1779.47,471.80 1782.75,471.14 1786.40,471.14
             1786.40,471.14 1791.53,471.14 1791.53,471.14
             1792.36,471.14 1793.05,471.43 1793.61,471.99
             1794.17,472.55 1794.46,473.24 1794.46,474.07
             1794.46,474.07 1794.46,486.07 1794.46,486.07
             1794.46,486.90 1794.17,487.59 1793.61,488.16
             1793.05,488.72 1792.36,488.99 1791.53,488.99
             1791.53,488.99 1780.24,488.99 1780.24,488.99
             1777.02,488.99 1774.53,489.87 1772.78,491.62
             1771.03,493.37 1770.16,495.86 1770.16,499.07
             1770.16,499.07 1770.16,528.91 1770.16,528.91
             1770.16,529.74 1769.87,530.43 1769.29,531.00
             1768.71,531.57 1768.01,531.85 1767.18,531.85
             1767.18,531.85 1752.62,531.85 1752.62,531.85 Z
           M 1830.11,533.01
           C 1820.90,533.01 1813.58,530.52 1808.14,525.53
             1802.70,520.54 1799.87,513.13 1799.65,503.32
             1799.61,502.79 1799.60,502.11 1799.60,501.28
             1799.60,500.45 1799.61,499.79 1799.65,499.31
             1799.89,493.14 1801.27,487.86 1803.78,483.47
             1806.30,479.08 1809.79,475.73 1814.27,473.42
             1818.75,471.12 1824.00,469.96 1830.03,469.96
             1836.84,469.96 1842.49,471.33 1846.98,474.07
             1851.48,476.80 1854.86,480.50 1857.11,485.19
             1859.38,489.87 1860.51,495.19 1860.51,501.12
             1860.51,501.12 1860.51,503.97 1860.51,503.97
             1860.51,504.78 1860.21,505.48 1859.63,506.04
             1859.05,506.61 1858.35,506.89 1857.52,506.89
             1857.52,506.89 1820.20,506.89 1820.20,506.89
             1820.20,506.95 1820.20,507.06 1820.20,507.22
             1820.20,507.38 1820.20,507.54 1820.20,507.69
             1820.25,509.98 1820.64,512.05 1821.36,513.89
             1822.10,515.73 1823.19,517.19 1824.65,518.28
             1826.11,519.36 1827.88,519.90 1829.97,519.90
             1831.51,519.90 1832.80,519.66 1833.85,519.19
             1834.91,518.71 1835.79,518.13 1836.51,517.46
             1837.22,516.79 1837.77,516.21 1838.16,515.73
             1838.86,514.92 1839.43,514.42 1839.86,514.22
             1840.30,514.02 1840.98,513.92 1841.89,513.92
             1841.89,513.92 1856.37,513.92 1856.37,513.92
             1857.13,513.92 1857.76,514.15 1858.26,514.61
             1858.76,515.06 1858.98,515.66 1858.91,516.41
             1858.82,517.74 1858.17,519.35 1856.94,521.23
             1855.71,523.12 1853.91,524.97 1851.53,526.77
             1849.16,528.57 1846.17,530.07 1842.58,531.25
             1838.99,532.42 1834.83,533.01 1830.11,533.01 Z
           M 1820.20,495.32
           C 1820.20,495.32 1839.95,495.32 1839.95,495.32
             1839.95,495.32 1839.95,495.06 1839.95,495.06
             1839.95,492.54 1839.56,490.35 1838.79,488.51
             1838.02,486.66 1836.90,485.23 1835.41,484.23
             1833.92,483.22 1832.13,482.72 1830.03,482.72
             1827.94,482.72 1826.16,483.22 1824.68,484.23
             1823.20,485.23 1822.09,486.66 1821.33,488.51
             1820.58,490.35 1820.20,492.54 1820.20,495.06
             1820.20,495.06 1820.20,495.32 1820.20,495.32 Z"
        />
        <path
          id="Path #3"
          fill="#f77f00"
          stroke="none"
          strokeWidth="1"
          d="M 1873.85,531.85
           C 1873.02,531.85 1872.32,531.57 1871.75,531.00
             1871.19,530.43 1870.91,529.74 1870.91,528.91
             1870.91,528.91 1870.91,514.11 1870.91,514.11
             1870.91,513.29 1871.19,512.60 1871.75,512.02
             1872.32,511.43 1873.02,511.14 1873.85,511.14
             1873.85,511.14 1888.65,511.14 1888.65,511.14
             1889.47,511.14 1890.17,511.43 1890.75,512.02
             1891.33,512.60 1891.62,513.29 1891.62,514.11
             1891.62,514.11 1891.62,528.91 1891.62,528.91
             1891.62,529.74 1891.33,530.43 1890.75,531.00
             1890.17,531.57 1889.47,531.85 1888.65,531.85
             1888.65,531.85 1873.85,531.85 1873.85,531.85 Z"
        />
        <path
          id="Imported Path #8"
          fill="currentColor"
          stroke="none"
          strokeWidth="1"
          d="M 116.12,441.41
           C 124.02,444.27 128.31,448.81 132.85,452.70
             171.23,484.37 215.05,503.20 264.98,504.24
             359.37,506.31 441.59,444.14 459.36,351.34
             474.39,273.20 448.85,208.44 384.14,162.10
             306.21,106.30 198.07,123.29 140.37,199.22
             102.37,249.20 94.08,305.01 114.04,364.58
             118.71,378.72 118.98,391.44 109.38,403.38
             101.21,413.51 90.31,417.41 77.60,415.71
             63.34,413.76 53.61,405.46 48.95,391.96
             42.08,371.98 37.40,351.34 36.24,330.31
             31.97,253.35 56.60,187.03 113.65,134.85
             165.13,87.61 226.72,66.20 296.88,73.20
             401.27,83.71 483.72,157.05 504.08,258.67
             529.25,384.31 451.44,503.97 326.57,527.34
             248.51,541.87 179.66,520.71 126.36,458.55
             123.64,455.31 121.43,451.54 119.10,448.04
             118.19,446.48 117.67,444.79 116.12,441.41 Z"
        />
        <path
          id="Imported Path #5"
          fill="#f77f00"
          stroke="none"
          strokeWidth="1"
          d="M 420.26,360.28
           C 420.26,360.28 255.77,360.79 255.77,360.79
             255.77,360.79 277.70,418.18 277.70,418.18
             277.70,418.18 254.74,418.18 254.74,418.18
             254.74,418.18 162.51,317.30 162.51,317.30
             162.51,317.30 400.41,317.30 400.41,317.30
             400.41,317.30 420.26,360.28 420.26,360.28 Z"
        />
        <path
          id="Imported Path #6"
          fill="#f77f00"
          stroke="none"
          strokeWidth="1"
          d="M 139.12,266.90
           C 139.12,266.90 303.76,266.38 303.76,266.38
             303.76,266.38 281.70,208.98 281.70,208.98
             281.70,208.98 304.79,208.98 304.79,208.98
             304.79,208.98 397.04,309.76 397.04,309.76
             397.04,309.76 158.97,309.76 158.97,309.76
             158.97,309.76 139.12,266.90 139.12,266.90 Z"
        />
      </g>
    </chakra.svg>
  )
}

export default Logo
